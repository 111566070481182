<template>
  <nuxt-link
    v-if="banner.ctaLink"
    :to="
      banner.ctaLink !== '' ? banner.ctaLink : localePath({ name: 'contacts' })
    "
    class="contact-banner is-rounded-wrap button-circle-1-trigger"
    :class="{
      'is-hover': isHover,
    }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    ref="parentbanner"
  >
    <div class="contact-banner__wrap">
      <div
        v-if="hasPreview"
        class="contact-banner-inside__video"
        data-animate="contact-banner-video"
      >
        <WidgetVideo
          class="is-cover"
          :videoUrl="banner.videoUrl"
          :loop="true"
          :forcePlay="isHover"
          :playInViewport="touch"
          :autoplay="false"
          :muted="true"
          :preload="'auto'"
        />
      </div>
      <div
        v-if="banner.title"
        v-html="banner.title"
        class="contact-banner__text is-text-center"
      />

      <div class="contact-banner__button">
        <div class="contact-banner__button__label button-round-1 is-big">
          <span>
            {{ banner.ctaLabel }}
          </span>
        </div>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  name: 'BannerCta',
}
</script>

<script setup>
import { gsap } from 'gsap'

const parentbanner = ref(null)
const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const ease = 'power2.inOut'

const props = defineProps({
  banner: Object,
})

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})

const hasPreview = computed(() => props.banner?.videoUrl)

const onMouseEnter = () => {
  if (touch.value) return false

  // const parent = parentbanner.value
  const video = document.querySelector('[data-animate="contact-banner-video"]')

  isHover.value = true

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onMouseLeave = () => {
  if (touch.value) return false

  /// const parent = parentbanner.value
  const video = document.querySelector('[data-animate="contact-banner-video"]')

  isHover.value = false

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
