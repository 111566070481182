<template>
  <WidgetBannerCta
    :banner="
      type === 2
        ? banner2?.title && banner2?.title !== ''
          ? banner2
          : banner
        : banner
    "
  />
</template>

<script>
export default {
  name: 'ContactBanner',
}
</script>

<script setup>
import queries from '@/graphql/dato/queries/index.js'

const { locale } = useI18n()

const props = defineProps({
  type: 1,
})

const query = queries.corporates.getContactBanner
const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(query, variables)

if (!data.value?.contactBanner) {
  throw createError({ statusCode: 404 })
}

const banner = computed(() => data.value?.contactBanner)
const banner2 = computed(() => data.value?.contactBanner2)
</script>

<style lang="scss">
@import './style.scss';
</style>
